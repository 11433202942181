import {
  ref, computed, watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import assetStoreModule from '../assetStoreModule';

export default function useAssetListModal(props, emit) {
  const STORE_MODULE_NAME = 'asset';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, assetStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModalAssetList = ref(null);
  const { t } = useI18nUtils();
  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
    },
    {
      label: t('Tên'),
      field: 'name',
    },
    {
      label: t('Giá trị'),
      field: 'price',
    },

  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {

    },
    sort: {},
    page: 1,
    perPage: 20,
  });

  const selectedAssetIds = computed(() => (props.handover ? props.handover.map(object => object.assetId) : []));

  const fetchAssets = () => {
    let filter = {};
    if (props.apartment) {
      filter = { ...filter, apartmentId: props.apartment.id };
    }
    if (props.room) {
      filter = { ...filter, roomId: props.room.id };
    }
    serverParams.value.filter = filter;

    store
      .dispatch('asset/fetchAssets', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items.filter(eachItem => !selectedAssetIds.value.includes(eachItem.id));
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAssets();
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   API Call
  const onSelectAssets = () => {
    refModalAssetList.value.toggle("#toggle-btn");
    emit("on-select-assets", selectedRows.value);
  };

  watch(selectedAssetIds, () => {
    fetchAssets();
  });

  return {
    refModalAssetList,
    columns,
    rows,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    fetchAssets,
    onSelectAssets,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    t,
  };
}
