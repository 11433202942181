<template>
  <b-modal
    id="modal-contract-detail"
    ref="refModalContractDetail"
    :title="t('Thông tin hợp đồng')"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-button
        v-if="!isTingTong && !applyNewContractTemplate"
        v-b-modal.modal-html-contract
        size="sm"
        class="mt-50 mr-1"
      >
        {{ t('Xem điều khoản') }}
      </b-button>
      <b-button
        v-if="!isTingTong && !applyNewContractTemplate"
        size="sm"
        class="mr-1 mt-50"
        variant="primary"
        @click="downloadContractPdf"
      >
        {{ t('Tải xuống (PDF)') }}
      </b-button>
      <b-button
        v-if="applyNewContractTemplate"
        class="mr-1 mt-50"
        size="sm"
        variant="primary"
        @click="downloadContractDocuments"
      >
        {{ t('Tải hợp đồng (Word)') }}
      </b-button>
      <b-button
        size="sm"
        class="mr-1 mt-50"
        variant="success"
        @click="sendContractEmail"
      >
        {{ t('Gửi email') }}
      </b-button>
      <b-button
        v-if="itemLocal.signStatus.id !== '3' && enableSignContract && itemLocal.status != 2"
        size="sm"
        class="mr-1 mt-50"
        variant="info"
        @click="sendContractSigningEmail"
      >
        {{ t('Gửi email ký hợp đồng') }}
      </b-button>
      <b-button
        v-if="itemLocal.signStatus.id !== '3' && enableSignContract && itemLocal.status != 2"
        v-clipboard:copy="copySigningUrl()"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        size="sm"
        class="mr-1 mt-50"
        variant="danger"
      >
        {{ t('Sao chép link ký HĐ') }}
      </b-button>
      <b-button
        v-if="isTingTong && $can('reCreate', 'invoice')"
        size="sm"
        class="mr-1 mt-50"
        variant="primary"
        @click="reCreateInvoiceForContract"
      >
        {{ t('Tạo lại hóa đơn hợp đồng mới') }}
      </b-button>
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1 pt-2"
      >
        1. {{ t('Thông tin chung') }}
      </div>
      <b-list-group class="pb-2">

        <b-list-group-item v-if="itemLocal.referenceCode">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Mã tham chiếu') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.referenceCode }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên tòa nhà') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.room">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên phòng') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.room.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.bed">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên giường') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.bed.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tenant">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Khách cọc') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giá thuê') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.price).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Đặt cọc') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.deposit).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.promotionMonth > 0 && itemLocal.promotionPricePerMonth > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Khuyến mại') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.promotionMonth }} tháng, mỗi tháng {{ Number(itemLocal.promotionPricePerMonth).toLocaleString() }} đ
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.promotionDeposit > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giảm trừ cọc') }}:
            </b-col>
            <b-col class="text-danger">
              {{ Number(itemLocal.promotionDeposit).toLocaleString() }} đ/tháng
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.promotionDeposit > 0 && itemLocal.price > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Cọc thực tế') }}:
            </b-col>
            <b-col class="text-danger">
              {{ Number(itemLocal.deposit - itemLocal.deposit/itemLocal.price * itemLocal.promotionDeposit).toLocaleString() }} đ
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.paymentPeriod">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Chu kỳ thanh toán') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.paymentPeriod.title }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày bắt đầu tính tiền') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.billingDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày ký') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.signDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày bắt đầu') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.startDate ) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày kết thúc') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.endDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.leavingDate">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày đăng ký chuyển') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.leavingDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.leavingTimeTracking">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày ghi nhận đăng ký chuyển') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.leavingTimeTracking, "DD-MM-YYYY HH:mm") }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.leavingNote">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ghi chú khách chuyển') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.leavingNote }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.note">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ghi chú') }}:
            </b-col>
            <b-col class="">
              <span style="white-space: pre-line;">{{ itemLocal.note }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.statusObject">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Trạng thái') }}:
            </b-col>
            <b-col class="">
              <b-badge
                pill
                :variant="itemLocal.statusObject.variant"
              >{{ itemLocal.statusObject.name }}</b-badge>
              <b-badge
                v-if="parseInt(itemLocal.statusObject.id, 10) === 2 && !itemLocal.isLiquidNormally"
                pill
                variant="light-warning"
                class="ml-50"
              >
                {{ t('Khách bỏ cọc') }}
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.liquidDate">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ itemLocal.isLiquidNormally ? t('Ngày thanh lý') : t('Ngày bỏ cọc') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.liquidDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="applyNewContractTemplate">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giấy tờ ký HĐ') }}:
            </b-col>
            <b-col class="">
              <div>
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(1)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">1. {{ t('Hợp đồng thuê') }}</span>
                </b-link>
              </div>
              <div
                v-if="!itemLocal.targetContractHistory && isEnableHandover"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(2)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. {{ t('BBBG tài sản') }}</span>
                </b-link>
              </div>
              <div
                v-if="itemLocal.targetContractHistory && isEnableHandover"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(5)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. {{ t('Biên bản nhận nhượng') }}</span>
                </b-link>
              </div>

            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="parseInt(itemLocal.status, 10) === 2 && applyNewContractTemplate">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giấy tờ thanh lý HĐ') }}:
            </b-col>
            <b-col class="">
              <div>
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(3)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">1. {{ t('BB thanh lý hợp đồng') }}</span>
                </b-link>
              </div>
              <div
                v-if="!itemLocal.sourceContractHistory && isEnableHandover"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(4)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. {{ t('BB bàn giao') }}</span>
                </b-link>
              </div>
              <div
                v-if="itemLocal.sourceContractHistory && isEnableHandover"
                class="mt-1"
              >
                <b-link
                  v-b-modal.modal-preview-pdf
                  variant="flat-info"
                  @click="setPreviewType(5)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50 font-medium-3"
                  />
                  <span class="">2. {{ t('Biên bản nhượng') }}</span>
                </b-link>
              </div>

            </b-col>

          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.createdAt">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ngày tạo') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.createdAt) }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary"
      >
        2. {{ t('Danh sách khách hàng') }}
      </div>
      <tenant-table :rows="itemLocal.tenants" />
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
      >
        3. {{ t('Dịch vụ sử dụng') }}
      </div>
      <fee-table :rows="itemLocal.contractFees" />
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
      >
        4. {{ t('Đính kèm') }}
      </div>
      <media-view
        v-model="itemLocal.attachments"
        :label="null"
        :initial-value="itemLocal.attachments"
        :upload-imediately="true"
        :target-object="{id: itemLocal.id, type: 'contract'}"
        :preview-mode="!$can('update', 'contract')"
        @on-delete-uploaded-file="onDeleteAttachment"
      />
      <div
        class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
      >
        5. {{ t('Lịch sử gia hạn') }}
      </div>
      <extend-history-table :rows="itemLocal.extendHistories" />

      <contract-html-modal :contract-id="itemLocal.id" />
      <contract-preview-modal :item="itemLocal" />
      <pdf-preview-modal
        :item="itemLocal"
        :title="t('Biên bản bàn giao tài sản')"
        :preview-type="previewType"
      />

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BLink,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import TenantTable from '@/views/property/room/detail/TenantTable.vue';
import { parseDateToString } from '@/auth/utils';
import MediaView from '@/views/components/media/MediaView.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import PdfPreviewModal from '@/views/components/pdf/PdfPreviewModal.vue';
import useContractDetailModal from './useContractDetailModal';
import FeeTable from './FeeTable.vue';
import ContractHtmlModal from '../html/ContractHTMLModal.vue';
import ExtendHistoryTable from './ExtendHistoryTable.vue';
import ContractPreviewModal from '../preview/ContractPreviewModal.vue';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    TenantTable,
    FeeTable,
    ContractHtmlModal,
    ExtendHistoryTable,
    MediaView,
    ContractPreviewModal,
    PdfPreviewModal,
    BLink,
  },
  props: {
    contractId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Đã sao chép',
          icon: 'BellIcon',
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: 'Không thể sao chép',
          icon: 'BellIcon',
        },
      });
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const userConfiguration = JSON.parse(localStorage.getItem('userConfiguration'));
    const enableSignContract = userConfiguration && userConfiguration.enableSigningContractOnline === true;
    const applyNewContractTemplate = userConfiguration && userConfiguration.applyNewContractTemplate === true;

    const {
      refModalDeleteCashbook,
      itemLocal,
      isTingTong,
      isEnableHandover,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      sendContractEmail,
      downloadContractPdf,
      downloadContractDocuments,
      onDeleteAttachment,
      sendContractSigningEmail,
      copySigningUrl,
      previewType,
      setPreviewType,
      t,
      reCreateInvoiceForContract,
    } = useContractDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      isTingTong,
      isEnableHandover,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      previewType,
      parseDateToString,
      sendContractEmail,
      enableSignContract,
      downloadContractPdf,
      downloadContractDocuments,
      onDeleteAttachment,
      sendContractSigningEmail,
      copySigningUrl,
      setPreviewType,
      applyNewContractTemplate,
      t,
      reCreateInvoiceForContract,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
