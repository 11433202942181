<template>
  <b-modal
    id="modal-transfer-contract"
    ref="refModalTransferContract"
    :title="t('Nhượng hợp đồng')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="!isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <div
          class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
        >
          1. {{ t('Thông tin hợp đồng') }}
        </div>
        <b-list-group class="py-2">

          <b-list-group-item v-if="itemLocal.apartment">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Tên tòa nhà') }}:
              </b-col>
              <b-col class="">
                {{ itemLocal.apartment.name }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.room">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Tên phòng') }}:
              </b-col>
              <b-col class="">
                {{ itemLocal.room.name }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.bed">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Tên giường') }}:
              </b-col>
              <b-col class="">
                {{ itemLocal.bed.name }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.tenant">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Khách cọc') }}:
              </b-col>
              <b-col class="">
                {{ itemLocal.tenant.name }}
              </b-col>
            </b-row>
          </b-list-group-item>

          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Giá thuê') }}:
              </b-col>
              <b-col class="">
                {{ Number(itemLocal.price).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Đặt cọc') }}:
              </b-col>
              <b-col class="">
                {{ Number(itemLocal.deposit).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.paymentPeriod">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Chu kỳ thanh toán') }}:
              </b-col>
              <b-col class="">
                {{ itemLocal.paymentPeriod.title }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày bắt đầu tính tiền') }}:
              </b-col>
              <b-col class="">
                {{ parseDateToString(itemLocal.billingDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày ký') }}:
              </b-col>
              <b-col class="">
                {{ parseDateToString(itemLocal.signDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày bắt đầu') }}:
              </b-col>
              <b-col class="">
                {{ parseDateToString(itemLocal.startDate ) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày kết thúc') }}:
              </b-col>
              <b-col class="">
                {{ parseDateToString(itemLocal.endDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.leavingDate">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày đăng ký chuyển') }}:
              </b-col>
              <b-col class="">
                {{ parseDateToString(itemLocal.leavingDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.note">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ghi chú') }}:
              </b-col>
              <b-col class="">
                {{ itemLocal.note }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="itemLocal.statusObject">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Trạng thái') }}:
              </b-col>
              <b-col class="">
                <b-badge
                  pill
                  :variant="itemLocal.statusObject.variant"
                >{{ itemLocal.statusObject.name }}</b-badge>
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>
        <div
          class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pt-2"
        >
          2. {{ t('Thông tin khách cũ') }}
        </div>
        <tenant-table :rows="itemLocal.tenants" />
        <div class="d-flex justify-space-between mt-2">
          <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
            3. {{ t('Thông tin khách mới') }}
          </div>
          <b-button
            v-b-modal.modal-list-tenant
            variant="primary"
            class="btn-icon ml-auto"
            size="sm"
            :disabled="itemLocal.transferTenants.length === 1 && !!itemLocal.bed"
          >
            <feather-icon icon="UserPlusIcon" />
          </b-button>
        </div>
        <b-row>
          <b-col cols="12">

            <validation-provider
              #default="validationContext"
              name="Khách thuê"
              rules="required|representor"
            >
              <new-tenant-table
                v-model="itemLocal.transferTenants"
                :rows="itemLocal.transferTenants"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <div
          class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary py-2"
        >
          4. {{ t('Thông tin nhượng') }}
        </div>
        <b-row>
          <b-col
            cols="6"
          >
            <validation-provider

              #default="validationContext"
              name="transferDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.transferDate"
                required
                :default-value="itemLocal.transferDate"
                :label="t('Ngày nhượng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>

          </b-col>
          <b-col
            cols="6"
          >
            <validation-provider

              #default="validationContext"
              name="transferEndDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.transferEndDate"
                required
                :default-value="itemLocal.transferEndDate"
                :label="t('Hạn hợp đồng mới')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>

          </b-col>
          <b-col cols="12">
            <b-form-group :label="t('Ghi chú')">
              <b-form-textarea
                id="transferNote"
                v-model="itemLocal.transferNote"
                :placeholder="t('Ghi chú')"
                rows="3"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                5. {{ t('Tiền phí dịch vụ') }}
              </div>
              <b-button
                v-b-modal.modal-list-service
                variant="primary"
                class="btn-icon ml-auto"
                size="sm"
              >
                <feather-icon icon="PlusSquareIcon" />
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">

            <fee-table
              :id="itemLocal.id"
              v-model="itemLocal.newContractFees"
              :rows="itemLocal.newContractFees"
              :apartment="itemLocal.apartment"
              :room="itemLocal.room"
              :is-transfer="true"
            />

          </b-col>
        </b-row>
        <b-row v-if="isEnableContractAssetCheck() && itemLocal.apartment && itemLocal.room">
          <b-col
            cols="12"
            class="mt-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                6. {{ t('Bàn giao tài sản 3 bên') }}
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <handover-table
              v-model="itemLocal.handover"
              :apartment="itemLocal.apartment"
              :room="itemLocal.room"
            />
          </b-col>
          <b-col cols="12">
            <other-handover-liquid-table
              v-model="itemLocal.otherHandover"
              :contract-id="itemLocal.id"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <list-tenant-modal
      :is-dormitory="!!itemLocal.bed"
      :tenants-selected="itemLocal.tenants"
      @selectTenant="onAddTenant"
    />
    <list-service-modal
      :item-local="itemLocal"
      :fees-selected="itemLocal.fees"
      :apartment="itemLocal.apartment"
      @selectService="onAddService"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, representor } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import { parseDateToString } from '@/auth/utils';
import TenantTable from '@/views/property/room/detail/TenantTable.vue';
import useTransferContractModal from './useTransferContract';
import ListTenantModal from "../listTenantModal/ListTenantModal.vue";
import ListServiceModal from '../listServiceModal/ListServiceModal.vue';
import NewTenantTable from '../modal/TenantTable.vue';
import HandoverTable from '../modal/HandoverTable.vue';
import FeeTable from '../modal/FeeTable.vue';
import OtherHandoverLiquidTable from '../liquidation/OtherHandoverLiquidTable.vue';

export default {
  components: {
    BModal,
    SelectDate,
    BRow,
    BCol,
    BBadge,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BListGroup,
    BListGroupItem,
    TenantTable,
    BButton,
    BFormGroup,
    BFormTextarea,
    ListTenantModal,
    NewTenantTable,
    HandoverTable,
    FeeTable,
    ListServiceModal,
    OtherHandoverLiquidTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      representor,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalTransferContract,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onAddTenant,
      onSubmit,
      onAddService,
      isEnableContractAssetCheck,
      t,
      isLoading,
      onOpen,
    } = useTransferContractModal(props, emit, refFormObserver);

    return {
      refModalTransferContract,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      onAddTenant,
      onAddService,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      isEnableContractAssetCheck,
      parseDateToString,
      t,
      isLoading,
      onOpen,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
