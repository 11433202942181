import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import moment from 'moment';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import contractStoreModule from '../contractStoreModule';

export default function useContractUpdateTime(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }
  const { t } = useI18nUtils();
  const toastification = toast();
  const refModalContractUpdateTime = ref(null);
  const refForm = ref(refFormObserver);
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const itemLocal = ref(JSON.parse(JSON.stringify({
    ...props.item, promotionMonth: 0, promotionPricePerMonth: 0, price: props.item.room && isTingTong ? props.item.room.price : props.item.price, extendNote: '', promotionDeposit: 0, oldPrice: props.item.price,
  })));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify({
      ...props.item, promotionMonth: 0, promotionPricePerMonth: 0, price: props.item.room && isTingTong ? props.item.room.price : props.item.price, extendNote: '', promotionDeposit: 0, oldPrice: props.item.price,
    }));
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };

  const extendMonth = computed(() => itemLocal.value.extendMonth);

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      contractId: itemLocal.value.id,
    };

    let dispatchName = "contract/extendContract";

    if (itemLocal.value.isExtend) {
      data.endDate = parseDateToString(itemLocal.value.newEndDate);
      data.price = itemLocal.value.price;
      data.promotionMonth = itemLocal.value.promotionMonth;
      data.promotionPricePerMonth = itemLocal.value.promotionPricePerMonth;
      data.price = itemLocal.value.price;

      data.extendNote = itemLocal.value.extendNote;
      // data.promotionDeposit = itemLocal.value.promotionDeposit;
      if (isTingTong && itemLocal.value.depositMonthObject && itemLocal.value.price > 0) {
        data.deposit = itemLocal.value.depositMonthObject.id * itemLocal.value.price;
      } else {
        data.deposit = itemLocal.value.depositMonthObject && itemLocal.value.price > 0 && Number(itemLocal.value.depositMonthObject.id) === Number(itemLocal.value.deposit) / Number(itemLocal.value.price) ? itemLocal.value.depositMonthObject.id * itemLocal.value.price : itemLocal.value.deposit;
      }
    } else {
      data.leavingDate = parseDateToString(itemLocal.value.leavingDate);
      data.leavingNote = itemLocal.value.leavingNote;
      dispatchName = "contract/updateLeavingDate";
    }
    isSubmitting.value = true;
    store
      .dispatch(dispatchName, data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalContractUpdateTime.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  watch(extendMonth, val => {
    if (val) {
      itemLocal.value.newEndDate = moment(itemLocal.value.endDate).add(val.id, 'months').toDate();
    } else {
      itemLocal.value.newEndDate = null;
    }
  });

  return {
    isTingTong,
    refModalContractUpdateTime,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
  };
}
