<template>
  <div>
    <small class="text-danger pt-2">{{ error }}</small>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="tenants"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: isRepresent -->
        <span v-if="props.column.field === 'isRepresent'">
          <b-form-checkbox
            v-model="props.row.isRepresent"
            name="check-button"
            switch
            inline
            @change="onChangeRepresent( $event, props.row)"
          />
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <table-actions
            v-if="!isTingTong || !enableLeavingDate"
            :name="t('Khách hàng')"
            :hide-edit="true"
            @on-delete="onDelete(props.row)"
          />
          <b-button
            v-else
            v-b-tooltip.hover.v-danger
            v-b-modal.modal-contract-remove-tenant
            variant="danger"
            class="btn-icon"
            size="sm"
            :title="t('Xoá')"
            @click="onSelectDeleteTenant(props.row)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'joinDate'">
          <validation-provider
            #default="validationContext"
            :name="`Ngày vào ${props.row.originalIndex}`"
            rules="required"
          >
            <select-date
              v-model="props.row.joinDate"
              :default-value="props.row.joinDate"
              :error="validationContext.errors[0]"
              @input="onChangeJoinDate( $event, props.row.originalIndex)"
            />

          </validation-provider>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          {{ t('Không có bản ghi nào!') }}
        </div>
      </div>
    </vue-good-table>
    <contract-remove-tenant-modal
      ref="refModalContractRemoveTenant"
      :tenant="selectedTenant"
      @on-submit="onConfirmLeavingDate"
    />
  </div>

</template>

<script>
import {
  BFormCheckbox,
  BButton,
  VBTooltip,
} from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import { VueGoodTable } from 'vue-good-table';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import SelectDate from '@/views/components/SelectDate.vue';
import { ValidationProvider } from "vee-validate";
import ContractRemoveTenantModal from '../remove-tenant/ContractRemoveTenantModal.vue';

export default {
  components: {
    BFormCheckbox,
    SelectDate,
    VueGoodTable,
    TableActions,
    ValidationProvider,
    BButton,
    ContractRemoveTenantModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    enableLeavingDate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tenants: this.rows,
      selectedTenant: null,
    };
  },

  watch: {
    rows(val) {
      this.tenants = val;
    },
  },

  methods: {
    onChangeRepresent(value, row) {
      if (this.tenants && this.tenants.length === 1) {
        this.tenants = this.tenants.map(tenant => ({ ...tenant, isRepresent: true }));
      } else {
        const tenantsChanged = this.tenants.map(tenant => (tenant.tenant.id === row.tenant.id
          ? { ...tenant, isRepresent: value }
          : { ...tenant, isRepresent: false }));
        this.tenants = tenantsChanged;
      }

      this.onChange();
    },
    onChangeJoinDate(value, index) {
      console.log(value);
      this.tenants[index].joinDate = value;
      this.onChange();
    },
    onDelete(row) {
      const remainTenants = this.tenants.filter(tenant => tenant.tenant.id !== row.tenant.id);
      if (remainTenants.filter(obj => obj.isRepresent).length === 0 && remainTenants.length > 0) {
        remainTenants[0].isRepresent = true;
      }
      this.tenants = remainTenants;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.tenants);
    },
    onConfirmLeavingDate(val) {
      console.log(val);
      this.onDelete(this.selectedTenant);
      if (this.selectedTenant && this.selectedTenant.tenant) {
        this.$emit('on-tenant-leave', { tenantId: this.selectedTenant.tenant.id, leavingDate: val });
      }
    },
    onSelectDeleteTenant(val) {
      this.selectedTenant = val;
    },
  },
  setup() {
    const { t } = useI18nUtils();
    const userConfiguration = JSON.parse(localStorage.getItem('userConfiguration'));
    const autoCalculateQuantityByTenant = !!(userConfiguration && userConfiguration.autoCalculateQuantityByTenant);
    // Table Handlers
    const columns = [
      {
        label: t('Tên cư dân'),
        field: 'tenant.name',
        sortable: false,
      },
      {
        label: t('Số điện thoại'),
        field: 'tenant.phone',
        sortable: false,
        width: "140px",
      },
      {
        label: t('Đại diện'),
        field: 'isRepresent',
        sortable: false,
        width: "100px",
      },
      {
        label: t('Ngày vào'),
        field: 'joinDate',
        sortable: false,
        width: "150px",
        hidden: !autoCalculateQuantityByTenant,
      },
      {
        label: t('Thao tác'),
        field: 'action',
        tdClass: 'text-center',
        sortable: false,
        width: "100px",
      },
    ];

    const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

    return {
      columns,
      t,
      isTingTong,
    };
  },
};
</script>
