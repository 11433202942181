<template>
  <div>

    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <b-form-spinbutton
        id="spin-button"
        v-model="value"
        :min="min"
        :max="max"
        :step="step"
        @input="onInputChange"
      />
      <small class="text-danger">{{
        error
      }}</small>
    </b-form-group>

  </div>
</template>

<script>
import { BFormSpinbutton, BFormGroup } from 'bootstrap-vue';

export default {
  components: {
    BFormSpinbutton,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [Number, String],
      default: 1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [Number, String],
      default: null,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    step: {
      type: [Number, String],
      default: 1,
    },

  },
  data() {
    return {
      value: Number(this.defaultValue) || 0,
    };
  },
  watch: {
    defaultValue(val) {
      this.value = Number(val) || 0;
    },
  },
  methods: {
    onInputChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>
