import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContracts(ctx, params) {
      return useJwt.getContracts(params).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getContractAnalytics(params).then(response => response);
    },
    getContractDetail(ctx, id) {
      return useJwt.getContractDetail(id).then(response => response);
    },
    createContract(ctx, data) {
      return useJwt.createContract(data).then(response => response);
    },
    updateContract(ctx, data) {
      return useJwt.updateContract(data).then(response => response);
    },
    extendContract(ctx, data) {
      return useJwt.extendContract(data).then(response => response);
    },
    liquidContract(ctx, data) {
      return useJwt.liquidContract(data).then(response => response);
    },
    deleteContracts(ctx, data) {
      return useJwt.deleteContracts(data).then(response => response);
    },
    fetchTenants(ctx, params) {
      return useJwt.getTenants(params).then(response => response);
    },
    fetchFees(ctx, params) {
      return useJwt.getFees(params).then(response => response);
    },
    importContracts(ctx, data) {
      return useJwt.importContracts(data).then(response => response);
    },
    exportContracts(ctx, params) {
      return useJwt.exportContracts(params).then(response => response);
    },
    generateImportContractTemplate(ctx, params) {
      return useJwt.generateImportContractTemplate(params).then(response => response);
    },
  },
};
