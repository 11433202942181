<template>
  <b-modal
    id="modal-asset-list"
    ref="refModalAssetList"
    size="lg"
    :title="t('Danh sách tài sản')"
    :ok-title="t('Chọn')"
    :cancel-title="t('Hủy')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    no-close-on-esc
    :ok-disabled="!selectedRows.length"
    @show="fetchAssets"
    @ok="onSelectAssets"
  >
    <b-row>
      <b-col cols="12">

        <vue-good-table
          style-class="vgt-table striped bordered"
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
          }"
          @on-selected-rows-change="selectionChanged"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="loadingContent">
            <b-spinner
              label="Loading"
              type="grow"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Code -->
            <span v-if="props.column.field === 'code'">
              <b-link
                v-b-modal.modal-asset-detail
                class="font-weight-bold"
                @click="onEditItem(props.row)"
              >
                {{ props.row.code }}
              </b-link>
            </span>

            <!-- Column: Price -->
            <span v-else-if="props.column.field === 'price'">
              {{ Number(props.row.price).toLocaleString() }}
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                <b-form-select
                  v-model="serverParams.perPage"
                  :options="['10', '20', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) =>
                      props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="serverParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => props.pageChanged({ currentPage: value })
                  "
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
          <div slot="emptystate">
            <div class="text-center text-muted">
              {{ t('Không có bản ghi nào!') }}
            </div>
          </div>
        </vue-good-table>

      </b-col>
    </b-row>

  </b-modal>

</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BPagination,

  BFormSelect,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
// eslint-disable-next-line import/no-cycle
import useAssetListModal from './useAssetListModal';
// eslint-disable-next-line import/no-cycle

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,

    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    handover: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      refModalAssetList,
      columns,
      rows,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchAssets,
      onSelectAssets,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      t,
    } = useAssetListModal(props, emit);

    return {
      refModalAssetList,
      columns,
      rows,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,
      fetchData,
      fetchAssets,
      onSelectAssets,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      t,
    };
  },

  created() {
    this.fetchData();
  },

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.asset-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 120px !important;

            &:first-child {
              min-width: 50px !important;
            }

            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 240px !important;
            }
            &:nth-child(5) {
               min-width: 150px !important;
            }
            &:nth-child(6) {
               min-width: 150px !important;
            }
            &:nth-child(7) {
               min-width: 150px !important;
            }
            &:nth-child(8) {
               min-width: 200px !important;
            }
            &:nth-child(12) {
               min-width: 200px !important;
            }
          }
        }
      }
    }
}
</style>
