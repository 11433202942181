<template>
  <b-modal
    id="modal-list-tenant"
    ref="my-modal"
    size="lg"
    :title="t('Danh Sách Cư Dân')"
    :ok-title="t('Chọn')"
    :cancel-title="t('Hủy')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    :ok-disabled="checkIsSubmit"
    @hidden="resetValue"
    @show="fetchTenants"
    @ok="handleSubmit"
  >
    <b-row>
      <b-col
        md="6"
        :lg="resolveColWidthIfDisableBed()"
      >
        <select-apartment v-model="apartment" />
      </b-col>
      <b-col
        md="6"
        :lg="resolveColWidthIfDisableBed()"
      ><select-room
        v-model="room"
        :filter-active="true"
        :apartment="apartment"
      /></b-col>
      <b-col
        v-if="resolveColWidthIfDisableBed(true) > 0"
        md="6"
        :lg="resolveColWidthIfDisableBed()"
      >
        <select-bed
          v-model="bed"
          :room="room"
          :filter-active="true"
        />
      </b-col>
      <b-col
        md="6"
        :lg="resolveColWidthIfDisableBed()"
      >
        <select-tenant-status v-model="status" />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        lg="3"
      >
        <select-foreign-filter v-model="foreign" />
      </b-col>
      <b-col
        md="6"
        lg="3"
      >
        <select-individual v-model="individual" />
      </b-col>
      <b-col
        md="8"
        lg="4"
      >
        <b-form-input
          v-model="searchTerm"
          class="mb-1"
          :placeholder="t('Tìm kiếm')"
          debounce="1000"
        />
      </b-col>
      <b-col
        md="4"
        lg="2"
      >
        <b-button
          v-b-modal.modal-tenant
          variant="primary"
          class="w-100 mr-0 px-0"
        > {{ t('Thêm mới') }} </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-alert
          :show="isDormitory"
          variant="danger"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">{{ t('Lưu ý: Đối với mô hình Ký túc xá/Homestay, bạn chỉ chọn 1 khách hàng duy nhất') }}.</span>
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <vue-good-table
          mode="remote"
          :total-rows="totalRecords"
          :is-loading.sync="isLoading"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
          }"
          @on-selected-rows-change="selectionChanged"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="loadingContent">
            <b-spinner
              label="Loading"
              type="grow"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Code -->
            <span v-if="props.column.field === 'code'">
              <b-link class="font-weight-bold">
                {{ props.row.code }}
              </b-link>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> {{ t('Hiển thị tối đa') }} </span>
                <b-form-select
                  v-model="serverParams.perPage"
                  :options="['10', '20', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ t('trên tổng số') }} {{ props.total }} {{ t('kết quả') }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="serverParams.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
          <div slot="emptystate">
            <div class="text-center text-muted">
              {{ t('Không có bản ghi nào!') }}
            </div>
          </div>
        </vue-good-table>
      </b-col>
    </b-row>
    <tenant-modal
      :item="blankItem"
      @refetch-data="fetchTenants"
    />
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BModal,
  VBModal,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import SelectApartment from "@/views/components/SelectApartment.vue";
import SelectRoom from "@/views/components/SelectRoom.vue";
import SelectBed from "@/views/components/SelectBed.vue";
import SelectTenantStatus from "@/views/components/SelectTenantStatus.vue";
import SelectForeignFilter from "@/views/components/SelectForeignFilter.vue";
import SelectIndividual from "@/views/components/SelectIndividual.vue";
import TenantModal from "@/views/contract/tenant/modal/TenantModal.vue";
import useListTenantModal from "./useListTenantModal";

export default {
  components: {
    BButton,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BModal,
    VueGoodTable,
    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectTenantStatus,
    SelectForeignFilter,
    SelectIndividual,
    TenantModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    isDormitory: {
      type: Boolean,
      default: false,
    },
    tenantsSelected: {
      type: Array,
      default: null,
    },
  },
  computed: {
    checkIsSubmit() {
      return (
        (this.selectedRows.length > 1 && this.isDormitory) || !this.selectedRows.length
      );
    },
  },
  setup(props) {
    const {
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      foreign,
      individual,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      blankItem,
      fetchTenants,
      t,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resetValue,
      resolveColWidthIfDisableBed,
    } = useListTenantModal(props);

    return {
      columns,
      rows,
      apartment,
      room,
      bed,
      status,
      foreign,
      individual,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      blankItem,
      fetchTenants,
      t,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      resetValue,
      resolveColWidthIfDisableBed,
    };
  },

  methods: {
    handleSubmit() {
      this.$emit("selectTenant", this.selectedRows);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
