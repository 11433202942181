import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeters(ctx, params) {
      return useJwt.getMeters(params).then(response => response);
    },
    createMeter(ctx, data) {
      return useJwt.createMeter(data).then(response => response);
    },
    updateMeter(ctx, data) {
      return useJwt.updateMeter(data).then(response => response);
    },
    getMeterDetail(ctx, id) {
      return useJwt.getMeterDetail(id).then(response => response);
    },
    deleteMeters(ctx, data) {
      return useJwt.deleteMeters(data).then(response => response);
    },
  },
};
