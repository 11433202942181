<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="modal-html-contract"
    ref="refModalContractHTML"
    :title="t('Nội dung hợp đồng')"
    size="lg"
    scrollable
    :ok-title="t('In HĐ')"
    :cancel-title="t('Đóng')"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="printContract"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <div v-html="itemLocal" />

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,

} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';

import useContractHTMLModal from './useContractHTMLModal';

export default {
  components: {
    BModal,
    BSpinner,

  },
  props: {
    contractId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      printContract,
      t,
    } = useContractHTMLModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      printContract,
      t,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
