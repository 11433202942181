<template>
  <div>
    <small class="text-danger pt-2">{{ error }}</small>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="tenants"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: isRepresent -->
        <span v-if="props.column.field === 'quantity'">
          {{ props.row.meter ? props.row.firstIndex : props.row.quantity }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          {{ t('Không có bản ghi nào!') }}
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>

import { VueGoodTable } from 'vue-good-table';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tenants: this.rows,
    };
  },

  watch: {
    rows(val) {
      this.tenants = val;
    },
  },

  setup() {
    // Table Handlers
    const { t } = useI18nUtils();

    // const isHiddenCoefficient = this.rows.filter(obj => obj.coefficient === 1).length === this.rows.length;

    const columns = [
      {
        label: t('Tên dịch vụ'),
        field: 'fee.name',
        sortable: false,
      },
      {
        label: t('Số lượng/Số đầu kỳ'),
        field: 'quantity',
        sortable: false,
      },
      {
        label: t('Hệ số'),
        field: 'coefficient',
        sortable: false,
        hidden: true,
      },
      {
        label: t('Công tơ'),
        field: 'meter.name',
        sortable: false,
      },
    ];

    return {
      columns,
      t,
    };
  },
};
</script>
