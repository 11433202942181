var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{staticClass:"mt-2",attrs:{"columns":_vm.columns,"rows":_vm.assets,"select-options":{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
  }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'note')?_c('span',[_c('b-form-input',{attrs:{"id":("note-" + (props.row.originalIndex)),"placeholder":"","trim":""},on:{"input":function($event){return _vm.changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)}},model:{value:(props.row.note),callback:function ($$v) {_vm.$set(props.row, "note", $$v)},expression:"props.row.note"}})],1):(props.column.field === 'status')?_c('span',[_c('b-form-input',{attrs:{"id":("status-" + (props.row.originalIndex)),"placeholder":"","trim":""},on:{"input":function($event){return _vm.changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)}},model:{value:(props.row.status),callback:function ($$v) {_vm.$set(props.row, "status", $$v)},expression:"props.row.status"}})],1):(props.column.field === 'penaltyFee')?_c('span',[_c('number-input',{attrs:{"default-value":props.row.penaltyFee},on:{"input":function($event){return _vm.changeCell(props.row['penaltyFee'], 'penaltyFee', props.row.originalIndex)}},model:{value:(props.row.penaltyFee),callback:function ($$v) {_vm.$set(props.row, "penaltyFee", $$v)},expression:"props.row.penaltyFee"}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }