import {
  ref,
} from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { parseDateToString } from '@/auth/utils';
import contractStoreModule from '../contractStoreModule';

export default function useContractRemoveTenant(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }
  const { t } = useI18nUtils();
  const refModalContractRemoveTenant = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref({ leavingDate: new Date() });
  const resetItemLocal = () => {
    itemLocal.value = { leavingDate: new Date() };
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    emit('on-submit', parseDateToString(itemLocal.value.leavingDate));
    refModalContractRemoveTenant.value.toggle('#toggle-btn');
  };

  return {
    refModalContractRemoveTenant,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    t,
  };
}
