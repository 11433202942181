<template>
  <div>
    <small class="text-danger pt-2">{{ error }}</small>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="tenants"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: isRepresent -->
        <span v-if="props.column.field === 'created_at'">
          {{ parseDateToString(props.row[props.column.field], "DD-MM-YYYY HH:mm") }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ parseDateToString(props.row[props.column.field]) }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          {{ t('Không có bản ghi nào!') }}
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>

import { VueGoodTable } from 'vue-good-table';

import { parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {

    VueGoodTable,

  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tenants: this.rows,
    };
  },

  watch: {
    rows(val) {
      this.tenants = val;
    },
  },

  setup() {
    const { t } = useI18nUtils();
    // Table Handlers
    const columns = [
      {
        label: t('Hạn cũ'),
        field: 'oldDate',
        sortable: false,
      },
      {
        label: t('Hạn mới'),
        field: 'newDate',
        sortable: false,
      },
      {
        label: t('Ngày thao tác'),
        field: 'created_at',
        sortable: false,
      },
    ];

    return {
      columns,
      parseDateToString,
      t,
    };
  },
};
</script>
