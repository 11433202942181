<template>
  <b-modal
    id="modal-contract-remove-tenant"
    ref="refModalContractRemoveTenant"
    :title="t('Khách rời phòng')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <b-col
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="leavingDate"
              rules="required"
            >
              <select-date
                v-model="itemLocal.leavingDate"
                required
                :default-value="itemLocal.leavingDate"
                :label="t('Ngày chuyển đi')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              /></validation-provider>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import { parseDateToString } from '@/auth/utils';
import useContractRemoveTenantModal from './useContractRemoveTenantModal';

export default {
  components: {
    BModal,
    SelectDate,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    tenant: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalContractRemoveTenant,
      refForm,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
    } = useContractRemoveTenantModal(props, emit, refFormObserver);

    return {
      refModalContractRemoveTenant,
      refForm,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      parseDateToString,
    };
  },
};
</script>
