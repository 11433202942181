import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAssets(ctx, params) {
      return useJwt.getAssets(params).then(response => response);
    },
    getAssetDetail(ctx, id) {
      return useJwt.getAssetDetail(id).then(response => response);
    },
    fetchAnalytics(ctx, params) {
      return useJwt.getAssetAnalytics(params).then(response => response);
    },
    exportAssets(ctx, params) {
      return useJwt.exportAssets(params).then(response => response);
    },
    generateImportTemplate(ctx, params) {
      return useJwt.generateImportAssetTemplate(params).then(response => response);
    },
    createAsset(ctx, data) {
      return useJwt.createAsset(data).then(response => response);
    },
    updateAsset(ctx, data) {
      return useJwt.updateAsset(data).then(response => response);
    },
    importAssets(ctx, data) {
      return useJwt.importAsset(data).then(response => response);
    },
    moveAsset(ctx, data) {
      return useJwt.moveAsset(data).then(response => response);
    },
    deleteAssets(ctx, data) {
      return useJwt.deleteAssets(data).then(response => response);
    },
  },
};
