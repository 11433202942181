import {
  ref, computed, watch,
} from '@vue/composition-api';
import store from '@/store';
import moment from "moment";
import toast from '@/utils/toast';
import invoiceStoreModule from '@/views/finance/invoice/invoiceStoreModule';
import { isEnableContractAssetCheck, parseDateToString } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import contractStoreModule from '../contractStoreModule';

export default function useContractLiquidation(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';
  const INVOICE_STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }
  if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) {
    store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const refModalLiquidation = ref(null);
  const refForm = ref(refFormObserver);
  const contract = computed(() => props.item);
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const isLiquidNormally = ref(true);

  const { t } = useI18nUtils();

  const blankItem = {
    invoices: [],
    deposit: 0,
    prepaid: 0,
    penaltyFee: 0,
    maxPrepaid: 0,
    liquidDate: moment(new Date()).toDate(),
    refundRoomEndDate: null,
    refundRoomQuantity: 0,
    refundRoomAmount: 0,
    liquidHandover: [],
    liquidOtherHandover: [],
    customerBankAcount: '',
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
    isSubmitting.value = false;
  };
  const liquidDate = computed(() => itemLocal.value.liquidDate);
  const diffDayToLiquidDate = computed(() => {
    if (moment(parseDateToString(liquidDate.value), "DD-MM-YYYY").isValid() && moment(contract.leavingTimeTracking).isValid() && moment(parseDateToString(liquidDate.value), "DD-MM-YYYY").isAfter(moment(contract.leavingTimeTracking))) {
      return Math.abs(moment(parseDateToString(liquidDate.value), "DD-MM-YYYY").diff(moment(contract.leavingTimeTracking), 'days')) + 1;
    }
    return -1;
  });
  const returnRoomStartDate = computed(() => (isTingTong && moment(parseDateToString(itemLocal.value.liquidDate), "DD-MM-YYYY", true).isValid() ? moment(parseDateToString(itemLocal.value.liquidDate), "DD-MM-YYYY", true).add(1, 'day').toDate() : itemLocal.value.liquidDate));

  const totalDebt = computed(() => {
    let tempValue = 0;
    itemLocal.value.invoices.forEach(invoice => {
      tempValue += invoice.remain;
    });
    return tempValue;
  });
  const total = computed(() => Number(itemLocal.value.deposit) + Number(itemLocal.value.prepaid) - Number(totalDebt));
  const totalRepairFee = computed(() => Number(itemLocal.value.penaltyFee) + (itemLocal.value.liquidHandover.reduce(
    (partialSum, a) => +partialSum + +(Number(a.repairFee)),
    0,
  ) || 0) + (+itemLocal.value.liquidOtherHandover.reduce(
    (partialSum, a) => +partialSum + +(Number(a.penaltyFee)),
    0,
  ) || 0));
  const remain = computed(() => Number(totalDebt.value) + Number(totalRepairFee.value) - Number(itemLocal.value.deposit) - Number(itemLocal.value.prepaid) - Number(itemLocal.value.refundRoomAmount));

  const fetchDebtInvoices = () => {
    if (contract.value && contract.value.id > 0) {
      const filter = {
        searchTerm: '',
        filter: {
          status: 2, // chi lay hoa don con no
          contractId: contract.value.id,
        },
      };

      store
        .dispatch('invoice/fetchInvoices', filter)
        .then(response => {
          itemLocal.value.invoices = response.data.items;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          itemLocal.value.invoices = [];
          isLoading.value = false;
        });
    }
  };

  const reCalculateQuantityAndTotal = () => {
    if (moment(contract.value.lastBillingDate).isValid() && moment(parseDateToString(itemLocal.value.liquidDate), "DD-MM-YYYY", true).isValid() && moment(contract.value.lastBillingDate).isAfter(moment(parseDateToString(itemLocal.value.liquidDate), "DD-MM-YYYY", true))) {
      store
        .dispatch('invoice/calculateQuantity', {
          startDate: parseDateToString(returnRoomStartDate.value), endDate: parseDateToString(contract.value.lastBillingDate), contractId: contract.value.id, isLiquid: true,
        })
        .then(response => {
          const { quantity } = response.data;
          itemLocal.value.refundRoomQuantity = quantity;
          itemLocal.value.refundRoomEndDate = contract.value.lastBillingDate;
          itemLocal.value.refundRoomAmount = ((Number(contract.value.price) - Number(contract.value.promotionDeposit)) * Number(quantity)).toFixed(0);

          // itemLocal.value.items[index].quantity = quantity;
          // // itemLocal.value.items[index].totalDays = totalDays;
          // itemLocal.value.items[index].subTotal = parseInt(((quantity) * itemLocal.value.items[index].price).toFixed(0), 10);
          // itemLocal.value.items[index].total = parseInt(((quantity) * itemLocal.value.items[index].price).toFixed(0), 10);
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          itemLocal.value.refundRoomAmount = 0;
          itemLocal.value.refundRoomEndDate = null;
          itemLocal.value.refundRoomQuantity = 0;
        });
    } else {
      itemLocal.value.refundRoomAmount = 0;
      itemLocal.value.refundRoomEndDate = null;
      itemLocal.value.refundRoomQuantity = 0;
    }
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const openModal = () => {
    isLoading.value = true;
    resetItemLocal();
    fetchDebtInvoices();
    reCalculateQuantityAndTotal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      deposit: !isLiquidNormally.value ? 0 : itemLocal.value.deposit,
      prepaid: !isLiquidNormally.value ? 0 : itemLocal.value.prepaid,
      penaltyFee: !isLiquidNormally.value ? 0 : itemLocal.value.penaltyFee,
      refundRoomAmount: !isLiquidNormally.value ? 0 : itemLocal.value.refundRoomAmount,
      refundRoomEndDate: !isLiquidNormally.value ? null : parseDateToString(contract.value.lastBillingDate),
      isLiquidNormally: isLiquidNormally.value,
      customerBankAccount: itemLocal.value.customerBankAccount,
    };

    if (props.item) {
      data.contractId = props.item.id;
    }
    data.liquidDate = parseDateToString(itemLocal.value.liquidDate);

    if (itemLocal.value.liquidHandover && itemLocal.value.liquidHandover.length > 0) {
      data.liquidHandover = itemLocal.value.liquidHandover.map(object => ({
        assetId: object.asset.id,
        note: object.note,
        repairFee: object.repairFee,
        isNormal: object.isNormal,
        base64String: object.base64String,
      }));
    }

    if (itemLocal.value.liquidOtherHandover && itemLocal.value.liquidOtherHandover.length > 0) {
      data.liquidOtherHandover = itemLocal.value.liquidOtherHandover.map(object => ({
        oldDataId: object.oldData.id,
        note: object.note,
        name: object.name,
        status: object.status,
        penaltyFee: object.penaltyFee,
      }));
    }

    isSubmitting.value = true;
    store
      .dispatch('contract/liquidContract', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalLiquidation.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastSuccess('Thanh lý hợp đồng thành công. Vui lòng kiểm tra mục Hóa đơn để xem & in hóa đơn thanh lý hợp đồng');
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const getRemainPrepaidByContract = val => {
    if (contract) {
      store.dispatch('prepaid/getPrepaidRemainByContract', { contractId: val.id })
        .then(response => {
          itemLocal.value.maxPrepaid = response.data;
          itemLocal.value.prepaid = response.data;
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  watch(contract, val => {
    getRemainPrepaidByContract(val);
    itemLocal.value.deposit = val.deposit;
  });

  watch(liquidDate, val => {
    if (val) {
      reCalculateQuantityAndTotal();
    }
  });

  const onLiquidDateChange = () => {
    reCalculateQuantityAndTotal();
  };

  const onLiquidNormallyChanged = newVal => {
    isLiquidNormally.value = newVal;
  };

  return {
    refModalLiquidation,
    refForm,
    contract,
    itemLocal,
    totalDebt,
    total,
    totalRepairFee,
    remain,
    isSubmitting,
    isLoading,
    isTingTong,
    isLiquidNormally,
    resetItemLocal,
    resetModal,
    onSubmit,
    openModal,
    isEnableContractAssetCheck,
    onLiquidDateChange,
    returnRoomStartDate,
    onLiquidNormallyChanged,
    t,
    diffDayToLiquidDate,
  };
}
