<template>
  <b-modal
    id="modal-contract-liquidation"
    ref="refModalLiquidation"
    :title="t('Thanh lý hợp đồng')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lập hóa đơn & Thanh lý')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="openModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>
    <!-- Body -->
    <validation-observer
      v-if="contract && !isLoading"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. {{ t('Thông tin hợp đồng') }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-list-group
          class="pb-1"
        >
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Đại diện') }}:
              </b-col>
              <b-col class="text-right">
                {{ contract.name }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày khách vào') }}:
              </b-col>
              <b-col class=" text-right">
                {{ parseDateToString(contract.startDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Hạn hợp đồng') }}:
              </b-col>
              <b-col class="text-right">
                {{ parseDateToString(contract.endDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item>
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Tiền đặt cọc') }}:
              </b-col>
              <b-col class="text-right">
                {{ Number(contract.deposit - contract.promotionDeposit * Number(contract.deposit/contract.price)).toLocaleString() }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="contract.leavingDate">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày đăng ký chuyển') }}:
              </b-col>
              <b-col class="text-right">
                {{ parseDateToString(contract.leavingDate) }}
              </b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item v-if="contract.leavingTimeTracking">
            <b-row>
              <b-col class="font-weight-bolder">
                {{ t('Ngày ghi nhận đăng ký chuyển') }}:
              </b-col>
              <b-col class="text-right">
                {{ parseDateToString(contract.leavingTimeTracking, "DD-MM-YYYY HH:mm") }} <span
                  v-if="diffDayToLiquidDate >= 0"
                  class="text-danger"
                >(Báo trước {{ diffDayToLiquidDate }} ngày)</span>
              </b-col>
            </b-row>
          </b-list-group-item>

        </b-list-group>

        <b-row>
          <b-col cols="6">
            <b-card
              no-body
              :class="`shadow-none border  ${isLiquidNormally? 'bg-light-primary' : 'bg-light-secondary'} `"
              @click="onLiquidNormallyChanged(true)"
            >
              <b-card-header>
                <b-card-title>{{ t('Khách rời phòng') }}</b-card-title>
                <b-form-radio
                  v-model="isLiquidNormally"
                  name="isLiquidNormally"
                  :value="true"
                  class="custom-control-primary"
                />
              </b-card-header>
            </b-card>

          </b-col>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border ${!isLiquidNormally ? 'bg-light-danger' : 'bg-light-secondary'} `"
              @click="onLiquidNormallyChanged(false)"
            >
              <b-card-header>
                <b-card-title>{{ t('Khách bỏ cọc') }}</b-card-title>
                <b-form-radio
                  v-model="isLiquidNormally"
                  name="isLiquidNormally"
                  :value="false"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
        </b-row>
        <div v-if="!isLiquidNormally">
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="liquidDate"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.liquidDate"
                  required
                  :default-value="itemLocal.liquidDate"
                  :label="t('Ngày bỏ cọc')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  @on-date-change="onLiquidDateChange(itemLocal.liquidDate)"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="isEnableContractAssetCheck()">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  {{ t('Bàn giao tài sản') }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isEnableContractAssetCheck()">
            <b-col cols="12">
              <handover-liquid-table
                v-model="itemLocal.liquidHandover"
                :apartment="contract.apartment"
                :room="contract.room"
                :contract-id="contract.id"
              />
            </b-col>
            <b-col cols="12">
              <other-handover-liquid-table
                v-model="itemLocal.liquidOtherHandover"
                :contract-id="contract.id"
              />
            </b-col>
          </b-row>
        </div>
        <div v-if="isLiquidNormally">
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="liquidDate"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.liquidDate"
                  required
                  :default-value="itemLocal.liquidDate"
                  :label="t('Ngày chuyển đi')"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  @on-date-change="onLiquidDateChange(itemLocal.liquidDate)"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  2. {{ t('Công nợ khách hàng') }}
                </div>
              </div>
            </b-col>
          </b-row>
          <invoice-table
            v-if="itemLocal.invoices.length > 0"
            :data="itemLocal.invoices"
          />
          <b-alert
            v-else
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span class="ml-25">{{ t('Khách hàng không còn nợ khoản tiền nào') }}.</span>
            </div>
          </b-alert>
          <b-row v-if="isEnableContractAssetCheck()">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  3. {{ t('Bàn giao tài sản') }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isEnableContractAssetCheck()">
            <b-col cols="12">
              <handover-liquid-table
                v-model="itemLocal.liquidHandover"
                :apartment="contract.apartment"
                :room="contract.room"
                :contract-id="contract.id"
              />
            </b-col>
            <b-col cols="12">
              <other-handover-liquid-table
                v-model="itemLocal.liquidOtherHandover"
                :contract-id="contract.id"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  {{ isEnableContractAssetCheck() ? '4' : '3' }}. {{ t('Hoàn cọc và tiền thừa') }}

                </div>
              </div>
            </b-col>
          </b-row>
          <b-alert
            v-if="itemLocal.maxPrepaid > 0"
            show
            variant="danger"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span class="ml-25">{{ t('Số tiền thừa đang giữ của khách là') }} {{ Number(itemLocal.maxPrepaid).toLocaleString() }}. (<b-link v-b-modal.prepaid-detail-modal>{{ t('Xem chi tiết') }}</b-link>)</span>
            </div>
          </b-alert>
          <b-row>
            <b-col cols="4">
              <validation-provider
                #default="validationContext"
                name="Hoàn trả cọc"
                :rules="`required|maxValue:${contract.deposit - contract.promotionDeposit * (contract.price > 0 ? Number(contract.deposit/contract.price) : 0)}`"
              >
                <number-input
                  v-model="itemLocal.deposit"
                  required
                  :label="t('Hoàn trả cọc?')"
                  :default-value="itemLocal.deposit"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider
                #default="validationContext"
                name="Hoàn trả tiền thừa"
                :rules="`required|maxValue:${itemLocal.maxPrepaid}`"
              >
                <number-input
                  v-model="itemLocal.prepaid"
                  required
                  :label="t('Hoàn trả tiền thừa?')"
                  :default-value="itemLocal.prepaid"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  :disabled="itemLocal.maxPrepaid <= 0"
                />
              </validation-provider>
            </b-col>
            <b-col cols="4">
              <validation-provider
                #default="validationContext"
                name="Phí phạt"
                rules=""
              >
                <number-input
                  v-model="itemLocal.penaltyFee"
                  :label="t('Phí phạt')"
                  :default-value="itemLocal.penaltyFee"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                :name="itemLocal.refundRoomEndDate ? `${t('Trả lại tiền phòng từ ngày')} ${parseDateToString(returnRoomStartDate)} ${t('đến ngày')} ${parseDateToString(itemLocal.refundRoomEndDate)}` : t('Trả lại tiền phòng')"
                :rules="`required`"
              >
                <number-input
                  v-model="itemLocal.refundRoomAmount"
                  required
                  :label="itemLocal.refundRoomEndDate ? `${t('Trả lại tiền phòng từ ngày')} ${parseDateToString(returnRoomStartDate)} ${t('đến ngày')} ${parseDateToString(itemLocal.refundRoomEndDate)}` : t('Trả lại tiền phòng')"
                  :default-value="itemLocal.refundRoomAmount"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  :disabled="false"
                />
              </validation-provider>
            </b-col>

          </b-row>
          <b-row v-if="isTingTong">
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="STK trả cọc"
                :rules="`required`"
              >
                <b-form-group :label="t('Tài khoản trả cọc')">
                  <template
                    v-slot:label
                  >
                    {{ t('Tài khoản trả cọc') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="customerBankAccount"
                    v-model="itemLocal.customerBankAccount"
                    :placeholder="t('Tài khoản trả cọc')"
                    rows="3"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  {{ isEnableContractAssetCheck() ? '5' : '4' }}. {{ t('Tổng hợp') }}
                </div>
              </div>
            </b-col>
          </b-row>

          <b-list-group
            class="pb-1"
          >
            <b-list-group-item>
              <b-row>
                <b-col class="font-weight-bolder">
                  {{ t('Tổng tiền khách nợ') }}: (1)
                </b-col>
                <b-col class="text-right">
                  {{ Number(totalDebt).toLocaleString() }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col class="font-weight-bolder">
                  {{ t('Tổng phí phạt') }}: (2)
                </b-col>
                <b-col class="text-right">
                  {{ Number(totalRepairFee).toLocaleString() }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col class="font-weight-bolder">
                  {{ t('Hoàn cọc') }}: (3)
                </b-col>
                <b-col class=" text-right">
                  {{ Number(itemLocal.deposit).toLocaleString() }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col class="font-weight-bolder">
                  {{ t('Hoàn trả tiền thừa') }}: (4)
                </b-col>
                <b-col class="text-right">
                  {{ Number(itemLocal.prepaid).toLocaleString() }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col class="font-weight-bolder">
                  {{ t('Trả lại tiền phòng') }}: (5)
                </b-col>
                <b-col class="text-right">
                  {{ Number(itemLocal.refundRoomAmount).toLocaleString() }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col class="font-weight-bolder">
                  {{ t('Tổng cộng') }}: (6) = (1) + (2) - (3) - (4) - (5)
                </b-col>
                <b-col class="text-right">
                  {{ remain.toLocaleString() }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item v-if="remain != 0">
              <span
                v-if="remain > 0"
                class="text-primary"
              >{{ `${t('Khách hàng cần thanh toán cho bạn')} ${remain.toLocaleString()}` }} đ</span>
              <span
                v-else
                class="text-danger"
              >{{ `${t('Bạn cần thanh toán cho khách hàng')} ${(remain * -1).toLocaleString()}` }} đ</span>

            </b-list-group-item>

          </b-list-group>
        </div>

      </b-form>
    </validation-observer>

    <prepaid-detail :item="{contract: contract, contractId: contract.id}" />
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BListGroupItem,
  VBTooltip,
  BLink,
  BSpinner,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormRadio,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import PrepaidDetail from '@/views/finance/prepaid/detail/PrepaidDetail.vue';
import { parseDateToString } from '@/auth/utils';
import InvoiceTable from './InvoiceTable.vue';
import useContractLiquidation from './useContractLiquidation';
import HandoverLiquidTable from './HandoverLiquidTable.vue';
import OtherHandoverLiquidTable from './OtherHandoverLiquidTable.vue';

export default {
  components: {
    BModal,
    BAlert,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BLink,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
    BFormInput,
    BFormGroup,
    SelectDate,
    InvoiceTable,
    NumberInput,
    BListGroup,
    BListGroupItem,
    PrepaidDetail,
    BSpinner,
    HandoverLiquidTable,
    OtherHandoverLiquidTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalLiquidation,
      itemLocal,
      totalDebt,
      total,
      contract,
      totalRepairFee,
      remain,
      isSubmitting,
      isLoading,
      isTingTong,
      returnRoomStartDate,
      isLiquidNormally,
      onLiquidNormallyChanged,
      resetItemLocal,
      resetModal,
      onSubmit,
      openModal,
      isEnableContractAssetCheck,
      onLiquidDateChange,
      t,
      diffDayToLiquidDate,
    } = useContractLiquidation(props, emit, refFormObserver);

    return {
      refModalLiquidation,
      itemLocal,
      totalDebt,
      total,
      totalRepairFee,
      contract,
      remain,
      isSubmitting,
      isLoading,
      isTingTong,
      returnRoomStartDate,
      isLiquidNormally,
      onLiquidNormallyChanged,
      resetItemLocal,
      resetModal,
      onSubmit,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      openModal,
      parseDateToString,
      isEnableContractAssetCheck,
      onLiquidDateChange,
      t,
      diffDayToLiquidDate,
    };
  },
};
</script>
