import {
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { getUserConfiguration } from '@/auth/utils';
import contractStoreModule from '../contractStoreModule';

export default function useContractDetailModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'contract';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, contractStoreModule);
  }

  const toastification = toast();
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(null);
  };
  const isLoading = ref(false);
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const userConfiguration = getUserConfiguration();
  const isEnableHandover = userConfiguration && userConfiguration.enableContractAssetCheck;

  const resetModal = () => {
    resetItemLocal();
  };
  const previewType = ref(1);
  const contractId = computed(() => props.contractId);
  const { t } = useI18nUtils();

  const fetchDetailData = () => {
    if (contractId.value) {
      isLoading.value = true;
      store.dispatch('contract/getContractDetail', contractId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };

  const reCreateInvoiceForContract = () => {
    if (contractId.value) {
      store.dispatch('contract/reCreateInvoiceForContract', contractId.value)
        .then(() => {
          toastification.showToastSuccess('Vui lòng kiểm tra hóa đơn mới tại mục Hóa đơn');
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const sendContractEmail = () => {
    if (contractId.value) {
      store.dispatch('contract/sendContractEmail', contractId.value)
        .then(() => {
          toastification.showToastSuccess('Hệ thống sẽ kiểm tra và gửi email cho khách nếu như khách có thông tin địa chỉ email');
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const sendContractSigningEmail = () => {
    if (contractId.value) {
      store.dispatch('contract/sendContractSigningEmail', contractId.value)
        .then(() => {
          toastification.showToastSuccess('Hệ thống sẽ kiểm tra và gửi email cho khách nếu như khách có thông tin địa chỉ email');
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const copySigningUrl = () => {
    if (itemLocal.value) {
      const represents = itemLocal.value.contractTenants.filter(obj => obj.isRepresent === true);
      if (represents && represents.length > 0) {
        return `${process.env.VUE_APP_URL}/sign-contract-v2?phone=${represents[0].tenant.phone}&contractNumber=${itemLocal.value.contractNumber}&secretCode=${itemLocal.value.secretCode}`;
      }
    }
    return `${process.env.VUE_APP_URL}/contract-finder`;
  };

  const downloadContractPdf = () => {
    if (contractId.value) {
      store.dispatch('contract/downloadContractPdf', contractId.value)
        .then(response => {
          window.location.href = response.data;
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };
  const downloadContractDocuments = () => {
    if (contractId.value) {
      store.dispatch('contract/downloadContractDocuments', contractId.value)
        .then(response => {
          window.location.href = response.data;
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const onDeleteAttachment = () => {

  };

  const setPreviewType = newValue => {
    previewType.value = newValue;
  };

  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };

  watch(contractId, () => {
    fetchDetailData();
  });

  return {
    refForm,
    itemLocal,
    isTingTong,
    resetItemLocal,
    resetModal,
    fetchDetailData,
    onOpen,
    isLoading,
    isEnableHandover,
    sendContractEmail,
    downloadContractPdf,
    onDeleteAttachment,
    sendContractSigningEmail,
    copySigningUrl,
    previewType,
    setPreviewType,
    downloadContractDocuments,
    t,
    reCreateInvoiceForContract,
  };
}
